import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';
import { cloneDeep } from 'lodash';

export type User = {
  email: string;
  username?: string;
  name?: string;
  surname?: string;
  groups: string;
};

export const useRegister = (): UseMutationResult<
  AxiosResponse,
  unknown,
  User,
  unknown
> => {
  const register = useMutation(
    async (user: User) => {
      const tempUser = {
        ...cloneDeep(user),
        password: '1234',
      } as any;
      tempUser.groups = [tempUser.groups];

      return axios(
        {
          method: 'post',
          data: tempUser,
          url: process.env.REACT_APP_AUTH_API + '/admin/user',
        },
        true
      );
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
  return register;
};

export default useRegister;
