import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetAllSources = (): UseQueryResult<any[]> => {
  const data_sources = useQuery(
    ['useGetAllSources'],
    async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/source_list/all`,
      });
      return data;
    }
  );
  return data_sources;
};

export default useGetAllSources;
