import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetAllRMBDistributionCompany = (
  year: string
): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['RMBDistributionCompany'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/rmb/dc/${year}`,
      });
      return data?.sort((a: any, b: any) => {
        return a?.distribution_company_name?.localeCompare(
          b?.distribution_company_name
        );
      });
    },
    refetchOnWindowFocus: false,
  });
  return data_sources;
};

export default useGetAllRMBDistributionCompany;
