import { EditOutlined } from '@ant-design/icons';
import PopConfirm from 'components/PopConfirm';
import { User } from 'types/commonTypes';

const DeleteOutlinedRoleBased =
  (userInfo: User | null) =>
  ({
    onClick,
    style,
    scheduled,
    shared,
  }: {
    onClick: any;
    style: any;
    scheduled?: boolean;
    shared?: boolean;
  }) => {
    if (userInfo?.groups.includes('botas-admin') && !shared && !scheduled)
      return (
        <EditOutlined
          style={style}
          onClick={(e: any) => {
            e.stopPropagation();
            onClick();
          }}
        />
      );
    else return <></>;
  };

export default DeleteOutlinedRoleBased;
