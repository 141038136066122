import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { IPredictionParameters } from 'types/Chart';
import { axios } from 'utils';
import { message } from 'antd';

export const useCreatePrediction = (): UseMutationResult<
  AxiosResponse,
  unknown,
  IPredictionParameters,
  unknown
> => {
  const createPrediction = useMutation({
    mutationFn: async (object: IPredictionParameters) => {
      return axios({
        method: 'post',
        url:
          process.env.REACT_APP_API +
          `/prediction/?prediction_type=${object.prediction_type}&layer=${
            object.layer
          }${
            object?.what_if_tables
              ? `&what_if_tables=${object.what_if_tables.toString()}`
              : ''
          }&start_run=${object.start_run}`,
      });
    },
    mutationKey: ['createPrediction'],
    onSuccess: (response) => {},
    onError: () => {
      message.error('Tahmin esnasında bir hata oluştu ! ');
    },
  });
  return createPrediction;
};

export default useCreatePrediction;
