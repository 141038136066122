import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { IChart } from 'types/Chart';

import { axios } from 'utils';

const useGetScoresOfPastPrediction = (
  predictionInfo: any,
  breakdowns: string[]
): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['useGetScoresOfPastPrediction'],
    queryFn: async (): Promise<IChart[]> => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/prediction/scores/?layer=${predictionInfo?.layer}&breakdowns=${breakdowns}`,
      });

      return data;
    },
    enabled: !!predictionInfo?.layer && !isEmpty(breakdowns),
  });
  return data_sources;
};
export default useGetScoresOfPastPrediction;
