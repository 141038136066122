import antdTR from "antd/lib/locale-provider/tr_TR";
import trMessages from "../locales/tr_TR.json";

const TrLang = {
  messages: {
    ...trMessages
  },
  antd: antdTR,
  locale: 'tr-TR',
};
export default TrLang;
