import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetAllTimelyPrediction = (): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['timely_prediction'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/timely_prediction`,
      });
      return data;
    },
  });
  return data_sources;
};

export default useGetAllTimelyPrediction;
