import { configureStore } from "@reduxjs/toolkit";
import { mainReducer } from "../slices/mainSlice";
import { chartReducer } from "redux/slices/chartSlice";
const store = configureStore({
  reducer: {
    main: mainReducer,
    chart: chartReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
export { store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
