import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'chart.js';
import SignIn from 'routes/SignIn';
import 'assets/vendors/style';
import './App.css';
import { checkToken } from 'utils';
import AppLocale from 'lngProvider';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import MainApp from 'containers/App';
import RequireAuth from 'components/RequireAuth';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import 'react-datasheet-grid/dist/style.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { store } from 'redux/store';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const SigninWithRedirect = (): JSX.Element => {
  return checkToken()?.access_token ? <Navigate to='/' /> : <SignIn />;
};
const queryClient = new QueryClient();

const currentAppLocale = AppLocale['tr'];
Chart.register(annotationPlugin, zoomPlugin);

function NextApp() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <BrowserRouter>
            <Routes>
              <Route element={<SigninWithRedirect />} path='/signin' />
              <Route
                path='/'
                element={
                  <RequireAuth>
                    <MainApp />
                  </RequireAuth>
                }
              >
                <Route path='/settings.user-management' />
                <Route path='/Account' />
                <Route path='/settings.system-info' />
                <Route path='/settings.system-settings' />
                <Route path='/settings.RMB-sector' />
                <Route path='/help' />
              </Route>
              <Route element={<SigninWithRedirect />} path='*' />
            </Routes>
          </BrowserRouter>
        </IntlProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default NextApp;
