import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonData, ICommonState, User } from 'types/commonTypes';

export const mainSlice = createSlice({
  name: 'mainSlice',
  initialState: {
    commonState: {
      commonData: {
        isLoading: true,
        navCollapsed: true,
        sideBarCollapsed: false,
        width: window.innerWidth,
        pathname: '/',
      },
    } as ICommonState,
    userInfo: null as User | null,
  },
  reducers: {
    setCommonData(state, { payload }: PayloadAction<ICommonData>) {
      state.commonState.commonData = {
        ...state.commonState.commonData,
        ...payload,
      };
    },
    setUserInfo(state, { payload }: PayloadAction<User | null>) {
      state.userInfo = payload;
    },
  },
});

export const mainActions = mainSlice.actions;
export const mainReducer = mainSlice.reducer;
