import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RMA } from 'types/RMA';

import { axios } from 'utils';

const useGetAllRmaByDistributionCompanyAndCity = (
  distribution_company_name?: string,
  city?: string
): UseQueryResult<RMA[]> => {
  const data_sources = useQuery(
    ['rmaByDistributionCompanyAndCity', distribution_company_name, city],
    async (): Promise<any[]> => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/rma/dc/?distribution_company_name=${distribution_company_name}&city_name=${city}`,
      });
      return data;
    },
    {
      enabled: !!distribution_company_name && !!city,
      refetchOnWindowFocus: false,
    }
  );
  return data_sources;
};

export default useGetAllRmaByDistributionCompanyAndCity;
