import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useUpdateMultipleDistributionCompanyRatio = (
  year: any
): UseMutationResult<AxiosResponse, unknown, any, unknown> => {
  const updateDistributionCompanyName = useMutation(
    async (data) => {
      return axios({
        method: 'post',
        data,
        url: process.env.REACT_APP_API + `/client/update/?year=${year}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return updateDistributionCompanyName;
};

export default useUpdateMultipleDistributionCompanyRatio;
