import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomScrollbars = (props: any) => (
  <Scrollbars
    ref={(scrollbar: any) => {
      if (props.startAtBottom) scrollbar?.scrollToBottom();
    }}
    {...props}
    autoHide
    renderTrackHorizontal={(props) => (
      <div
        {...props}
        style={{ display: 'none' }}
        className='track-horizontal'
      />
    )}
  />
);

export default CustomScrollbars;
