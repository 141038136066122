import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetBreakdowns = (): any => {
  const data_sources = useMutation({
    mutationFn: async (object: {
      is_past: boolean;
      run_id: string | null;
      end_date?: Date | string | null;
      start_date?: Date | string | null;
      layer: string | null;
    }) => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/prediction/breakdowns/?${
            (object.is_past
              ? Object.keys(object).includes('start_date')
                ? 'start_date=' +
                  object.start_date +
                  '&end_date=' +
                  object.end_date
                : object.run_id &&
                  'start_date=' +
                    JSON.parse(object.run_id).start_date +
                    '&end_date=' +
                    JSON.parse(object.run_id).end_date
              : 'run_id=' + object.run_id) +
            '&layer=' +
            object.layer
          }`,
      });
      return data;
    },
    mutationKey: ['breakdowns'],
  });
  return data_sources;
};
export default useGetBreakdowns;
