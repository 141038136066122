import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { KeycloakResponse } from 'types/commonTypes';
import { checkToken } from 'utils';
import { axios } from 'utils';

const AUTH_URL = (process.env.REACT_APP_AUTH_API + '/auth/refresh') as string;

const useRefreshToken = (): UseQueryResult<any> => {
  return useQuery(
    ['refresh_token'],
    async () => {
      const { data } = await axios({
        method: 'post',
        url: AUTH_URL as string,
        data: { refresh_token: checkToken()?.refresh_token as string },
      });
      localStorage.setItem('token', JSON.stringify(data as KeycloakResponse));
      return data;
    },
    {
      enabled: true, // turned off by default, manual refetch is needed
      refetchInterval: 295 * 1000,
      refetchOnWindowFocus: true,
      retry: 1,
      onSuccess: (data) => {
        localStorage.setItem('token', JSON.stringify(data as KeycloakResponse));
      },
      onError: (error) => {
        console.log('useRefreshToken : ', error);
        localStorage.removeItem('token');
      },
    }
  );
};

export default useRefreshToken;
