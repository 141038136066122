import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetWhatIfFile = (
  source: string | null,
  dates?: { start_date: any; end_date: any } | null
): UseQueryResult<any[]> => {
  const data_sources = useQuery(
    ['useGetWhatIfFile'],
    async (): Promise<any[]> => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/what_if?${
            dates
              ? 'start_date=' +
                dates?.start_date +
                '&end_date=' +
                dates.end_date +
                '&'
              : ''
          }source_name=${source}`,
      });
      return data;
    },
    {
      enabled: !!source && !!dates,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  return data_sources;
};

export default useGetWhatIfFile;
