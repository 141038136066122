import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export type User = {
  email: string;
  username?: string;
  name?: string;
  surname?: string;
  groups: string;
};

export const useSendResetPassword = (): UseMutationResult<
  AxiosResponse,
  unknown,
  User,
  unknown
> => {
  const reset_password = useMutation(
    async (user: User) => {
      return axios(
        {
          method: 'post',
          url:
            process.env.REACT_APP_AUTH_API +
            `/user/${user.email}/reset-password`,
        },
        false
      );
    },
    {
      onSuccess: (_response) => {
        message.success({
          content:
            'Sıfırlama Maili Gönderildi' /*<IntlMessages id="forgot-password.mail" />*/,
          duration: 2,
        });
      },
      onError: () => {},
    }
  );
  return reset_password;
};

export default useSendResetPassword;
