import { Button, Form, Input, message } from "antd";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import { useSignin } from "hooks";
import "assets/vendors/style";
import { useEffect } from "react";
import IntlMessages from "utils/IntlMessages";
import ForgotPassword from "./ForgotPassword";
import * as lngData from "lngProvider/locales/tr_TR.json";
import DataBossImg from "assets/Logo";
const FormItem = Form.Item;
const langData = lngData as any;
const SignIn = () => {
  const { mutate: signin, isError: isErrorSignin, isLoading } = useSignin();

  const onFinishFailed = (_errorInfo: any) => {};

  const onFinish = (values: any) => {
    signin(values);
  };

  useEffect(() => {
    if (isErrorSignin)
      message.error(
        "Please make sure to enter correct credentials and try again."
      );
  }, [isErrorSignin]);

  return (
    <div className="gx-login-container">
      <div style={{ fontSize: "22px" }}>
        <IntlMessages id="app.title" />
      </div>

      <div className="gx-login-content">
        <div style={{ textAlign: "center", marginBottom: 25 }}>
          <DataBossImg />
        </div>
        <div className="gx-login-header gx-text-center">
          <h1 className="gx-login-title">
            <IntlMessages id="app.login-page" />
          </h1>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[
              { required: true, message: <IntlMessages id="app.login-mail" /> },
            ]}
            name={"username"}
          >
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={langData["app.login-mail-placeholder"]}
            />
          </FormItem>
          <FormItem
            rules={[
              {
                required: true,
                message: <IntlMessages id="app.login-password" />,
              },
            ]}
            name="password"
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder={langData["app.login-password-placeholder"]}
            />
          </FormItem>
          <FormItem className="gx-text-center">
            <Button loading={isLoading} type="primary" htmlType="submit">
              <IntlMessages id="app.login" />
            </Button>
          </FormItem>
          <FormItem className="gx-text-center">
            <ForgotPassword />
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
