import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetRmbWithClientRatios = (year: string): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['rmbWithClientRatios'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/rmb/with_client?year=${year}`,
      });
      return data;
    },
    refetchOnWindowFocus: false,
  });
  return data_sources;
};

export default useGetRmbWithClientRatios;
