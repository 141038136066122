import { User } from 'types/commonTypes';

const SettingsHOC =
  (Settings: any) =>
  ({ userInfo }: { userInfo: User | undefined }) => {
    if (userInfo?.groups.includes('botas-admin')) return <Settings />;
    else return <></>;
  };

export default SettingsHOC;
