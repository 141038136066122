import { isEmpty } from 'lodash';

export const getChangedObjectWithArrayValues = (
  changedData: any,
  rawData: any,
  parameter: string[],
  arrayParameters: string[]
) => {
  const results = changedData.filter((e: any) =>
    rawData.some((e2: any) => {
      return (
        e2.rmb_point_code === e.rmb_point_code &&
        (parameter.some((param) => e2[param] !== e[param]) ||
          arrayParameters.some((param2) => {
            let difference = e2[param2]
              .filter((e22: any) => !e[param2].includes(e22))
              .concat(
                e[param2].filter((e11: any) => !e2[param2].includes(e11))
              );
            return !isEmpty(difference);
          }))
      );
    })
  );
  return results;
};
