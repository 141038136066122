import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useUpdateSubSector = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const updateSubSector = useMutation(
    async ({ subsector, newName }: { subsector: any; newName: string }) => {
      return axios({
        method: 'post',
        data: { name: newName },
        url: process.env.REACT_APP_API + `/sub_sector/${subsector.name}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return updateSubSector;
};

export default useUpdateSubSector;
