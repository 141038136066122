import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IFilteredQueryParameter } from 'types/Chart';

import { axios } from 'utils';

const useGetFilteredPrediction = (
  object: IFilteredQueryParameter | undefined
): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['filteredQuery'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/prediction/filter/?run_id=${object?.run_id}&is_sum_breakdowns=${
            object?.is_sum_breakdowns
          }&breakdowns=${object?.breakdowns.toString()}&is_sum_sectors=${
            object?.is_sum_sectors
          }&sectors=${object?.sectors}&is_customer_portfolio=${
            object?.is_customer_portfolio
          }&is_past=${object?.is_past}&layer=${
            object?.layer
          }&company=${object?.company.toString()}`,
      }).catch((err) => ({
        data: [],
      }));

      return data;
    },
    enabled:
      !!object?.run_id ||
      !!(object?.layer && object?.start_date && object?.end_date),
    refetchOnWindowFocus: false,
  });
  return data_sources;
};
export default useGetFilteredPrediction;
