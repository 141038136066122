import React from 'react';
import logo from './logo.png';
const DatabossImg = () => {
  return (
    <img
      src={logo}
      style={{ maxWidth: '70%', maxHeight: '100%', marginRight: 30 }}
      alt='databoss-logo'
    ></img>
  );
};

export default React.memo(DatabossImg);
