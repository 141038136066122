import { useLocation } from 'react-router-dom';

export const Help = () => {
  const location = useLocation();
  return (
    <div
      style={{ margin: 10, fontSize: 24 }}
      dangerouslySetInnerHTML={{ __html: location.state.helperText }}
    ></div>
  );
};
