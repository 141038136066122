import { useState } from 'react';
import { Button, Drawer, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import CustomScrollbars from 'utils/CustomScrollbars';
import { SettingOutlined } from '@ant-design/icons';
import IntlMessages from 'utils/IntlMessages';

const Settings = () => {
  const navigate = useNavigate();

  const [isCustomizerOpened, setIsCustomizerOpened] = useState(false);

  const toggleCustomizer = () => {
    setIsCustomizerOpened(!isCustomizerOpened);
  };

  const getCustomizerContent = () => {
    function getItem(id: string): any {
      return (
        <div onClick={() => navigate(id)} className='gx-customizer-item'>
          <h6 className='gx-mb-3 gx-text-uppercase'>
            <Button type='text'>
              <IntlMessages id={id}></IntlMessages>
            </Button>
          </h6>
        </div>
      );
    }

    return (
      <CustomScrollbars className='gx-customizer'>
        {getItem('settings.system-settings')}
        {getItem('settings.system-info')}
        {getItem('settings.user-management')}
        {getItem('settings.RMB-sector')}
      </CustomScrollbars>
    );
  };

  return (
    <>
      <Drawer
        placement='right'
        closable={false}
        onClose={toggleCustomizer}
        open={isCustomizerOpened}
      >
        {getCustomizerContent()}
      </Drawer>
      <div className='gx-customizer-option'>
        <Tooltip title={<IntlMessages id='app.settings' />}>
          <Button type='primary' onClick={toggleCustomizer}>
            <SettingOutlined />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default Settings;
