import { Collapse, Descriptions, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { memo, useEffect, useState } from 'react';
import IntlMessages from 'utils/IntlMessages';
import Fuse from 'fuse.js';
import { useIsFetching } from '@tanstack/react-query';
import { useGetScoresOfPastPrediction } from 'hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TransposeScores } from 'utils/TransposeScores';

const { Panel } = Collapse;

const Information = ({ breakdowns }: any) => {
  const [searchInput, setSearchInput] = useState('');

  const isFetchingChartData = useIsFetching({
    queryKey: ['filteredQuery'],
  });
  const [queryFetchedOnce, setQueryFetchedOnce] = useState(false);

  const selectedChart = useSelector(
    (state: RootState) => state.chart.selectedChart
  );
  const queryParameter = useSelector(
    (state: RootState) => state.chart.queryParameters
  );

  const { data: scoreData, refetch } = useGetScoresOfPastPrediction(
    selectedChart ?? queryParameter,
    breakdowns
  );

  const TransposedScores = TransposeScores(scoreData) as any;
  const fuse = new Fuse(Object.keys(TransposedScores));
  const SearchedScores = fuse.search(searchInput);

  useEffect(() => {
    if (isFetchingChartData) {
      setQueryFetchedOnce(true);
    } else {
      if (queryFetchedOnce) {
        refetch();
      }
    }
  }, [isFetchingChartData]);

  const searchInputOnChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  return (
    <Collapse style={{ marginTop: '10px' }}>
      <Panel
        header={
          <Row style={{ marginLeft: 2 }}>
            <IntlMessages id='app.information' />
            <Input
              onClick={(e) => e.stopPropagation()}
              onChange={searchInputOnChange}
              style={{ marginLeft: 10, maxWidth: '100px' }}
              size='small'
              placeholder='Kırılım ara'
              prefix={<SearchOutlined />}
            />
          </Row>
        }
        key='0'
      >
        <Collapse>
          {SearchedScores?.map((e: any, index: number) => (
            <Panel header={e.item} key={index}>
              <Descriptions title='Modellerin Eğitim Skor Bilgileri (MAPE)'>
                {Object.keys(TransposedScores[e.item])?.map(
                  (model_name: string) => {
                    return TransposedScores[e.item][model_name].map(
                      (element: any) => {
                        return (
                          <Descriptions.Item
                            label={`${element.model_name} ${element.sector}`}
                          >
                            {element.score.slice(
                              0,
                              element.score.indexOf('.') + 3
                            )}
                          </Descriptions.Item>
                        );
                      }
                    );
                  }
                )}
              </Descriptions>
            </Panel>
          ))}
          {searchInput === '' &&
            Object.keys(TransposedScores).map((e: any, index: number) => (
              <Panel header={e} key={index}>
                <Descriptions title='Modellerin Eğitim Skor Bilgileri (MAPE)'>
                  {Object.keys(TransposedScores[e])?.map(
                    (model_name: string) => {
                      return TransposedScores[e][model_name].map(
                        (element: any) => {
                          return (
                            <Descriptions.Item
                              label={`${element.model_name} ${element.sector}`}
                            >
                              {element.score.slice(
                                0,
                                element.score.indexOf('.') + 3
                              )}
                            </Descriptions.Item>
                          );
                        }
                      );
                    }
                  )}
                </Descriptions>
              </Panel>
            ))}
        </Collapse>
      </Panel>
    </Collapse>
  );
};

export default memo(Information);
