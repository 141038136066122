import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useCreateSubSector = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const createSubSector = useMutation(
    async ({ newName }: { newName: string }) => {
      return axios({
        method: 'post',
        data: { name: newName },
        url: process.env.REACT_APP_API + `/sub_sector`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return createSubSector;
};

export default useCreateSubSector;
