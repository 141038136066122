import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { User } from 'types/commonTypes';

import { axios } from 'utils';

const useGetUserInfo = (): UseQueryResult<User> => {
  const data_sources = useQuery({
    queryKey: ['userInfo'],
    queryFn: async (): Promise<User> => {
      const { data } = await axios({
        url: process.env.REACT_APP_AUTH_API + `/self`,
      });
      return data;
    },
  });

  return data_sources;
};

export default useGetUserInfo;
