import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useSendWhatIfData = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const sendWhatIfData = useMutation(
    async ({ file, layer }: { file: any; layer: 'city' | 'rma' }) => {
      const formData = new FormData();
      formData.append(`file`, file);
      return axios({
        method: 'post',
        data: formData,
        url: process.env.REACT_APP_API + `/what_if?layer=${layer}`,
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
  return sendWhatIfData;
};

export default useSendWhatIfData;
