import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IMETADATA } from 'types/RMBTypes';

import { axios } from 'utils';

const useGetGeneralInfo = ({
  date,
}: {
  date: string;
}): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['metadata'],
    queryFn: async (): Promise<IMETADATA[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/metadata/${date}`,
      });

      return data;
    },
  });
  return data_sources;
};

export default useGetGeneralInfo;
