import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IContinuesLogs } from 'types/Logs';

import { axios } from 'utils';

const useGetContinuesLogs = (dates: {
  start_date: string | null;
  end_date: string | null;
}): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['pipeline_status_view'],
    queryFn: async (): Promise<IContinuesLogs[]> => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/pipeline_status_view${
            dates?.start_date
              ? `?start_date=${dates.start_date}&end_date=${dates.end_date}`
              : ''
          }`,
      });
      return data;
    },
  });
  return data_sources;
};

export default useGetContinuesLogs;
