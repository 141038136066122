import { format } from 'date-fns';
import { keyColumn, textColumn } from 'react-datasheet-grid';
const msOfDay = 24 * 60 * 60 * 1000;
export const getColumnsOfInfoTable = ({
  chartEndDate,
  chartStartDate,
}: any) => {
  let tempColumns: any = [];
  tempColumns = [
    {
      ...keyColumn<any, 'label'>('label', textColumn),
      title: 'Parametre',
      width: '0 0 350px',
    },
  ];

  if (chartStartDate && chartEndDate)
    for (let i = 0; i <= (chartEndDate - chartStartDate) / msOfDay; i++) {
      tempColumns.push({
        ...keyColumn<any, 'label'>(
          (chartStartDate + msOfDay * i).toString(),
          textColumn
        ),
        title: format(new Date(chartStartDate + msOfDay * i), 'dd/MM/yyyy'),
        width: 1,
        component: ({ rowData }: any) => {
          return (
            <div style={{ fontSize: '0.9rem', margin: 5 }}>
              {rowData[chartStartDate + msOfDay * i]?.toLocaleString()}
            </div>
          );
        },
      });
    }

  return tempColumns;
};
