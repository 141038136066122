/**TODO : endpointten gelen data arrayindeki elemanlara unique key eklenmesi gerekiyor  */
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import IntlMessages from 'utils/IntlMessages';
import PopConfirm from './PopConfirm';

interface Item {
  user_email: string;
  name: string;
  surname: string;
  authority: string;
  id: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  inputType: 'number' | 'text' | 'dropdown';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber />
    ) : inputType === 'dropdown' ? (
      <Select>
        <Select.Option key={'Admin'}>Admin</Select.Option>
        <Select.Option key={'Kullanıcı'}>Kullanıcı</Select.Option>
      </Select>
    ) : (
      <Input style={{ width: '90%', marginLeft: 10 }} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ width: '70%', float: 'right', marginRight: '15px' }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({
  extraOperation,
  columns = [],
  data = null,
  pagination = false,
  headerText = '',
  headerComponent = null,
  isLoading = false,
  deleteRow,
  footer,
  size = 'middle',
  editDisabledRowTitles = [],
  setChangedRow,
}: {
  extraOperation?: string[];
  columns?: any;
  data?: any;
  pagination?: any;
  headerText?: string | JSX.Element;
  headerComponent?: any;
  isLoading?: boolean;
  footer?: () => JSX.Element;
  size?: 'small' | 'middle' | 'large';
  editDisabledRowTitles?: string[];
  deleteRow?: any;
  setChangedRow?: any;
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: Item) => {
    return record.id === editingKey;
  };
  const edit = (record: Partial<Item> & { id: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };
  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  const save = async (
    e: any,
    id: React.Key,
    record: any,
    setChangedRow: any
  ) => {
    e.stopPropagation();
    try {
      const row: any = (await form.validateFields()) as Item;
      setChangedRow({ email: record.email, ...row });

      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setEditingKey('');
      } else {
        newData.push(row);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const accept = async (e: any, _record: any) => {
    e.stopPropagation();
  };

  const editableColumns = [
    ...columns,
    {
      title: <IntlMessages id='user-management.operation' />,
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        const isDisable = editDisabledRowTitles.includes(record.user_email);
        return editable ? (
          <span>
            <Typography.Link
              onClick={(e) => save(e, record.id, record, setChangedRow)}
              style={{ marginRight: 8 }}
            >
              <IntlMessages id='table.save' />
            </Typography.Link>
            <PopConfirm onConfirm={cancel}>
              <a>
                <IntlMessages id='table.cancel' />
              </a>
            </PopConfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={isDisable || editingKey !== ''}
              style={{ marginRight: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                edit(record);
              }}
            >
              <IntlMessages id='configuration.RMB.table.edit' />
            </Typography.Link>
            {extraOperation?.includes('Delete') && (
              <PopConfirm onConfirm={(e: any) => deleteRow(e, record)}>
                <a>
                  <IntlMessages id='table.delete' />
                </a>
              </PopConfirm>
            )}
            {extraOperation?.includes('Accept') && (
              <PopConfirm onConfirm={(e: any) => accept(e, record)}>
                <a>
                  <IntlMessages id='table.accept' />
                </a>
              </PopConfirm>
            )}
          </span>
        );
      },
    },
  ];

  const mergedColumns = editableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => {
        return {
          record,
          inputType: col.dataIndex == 'authority' ? 'dropdown' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        title={
          headerText || headerComponent
            ? () => (
                <Row>
                  {headerText && (
                    <Col xs={24} sm={24}>
                      {headerText}
                    </Col>
                  )}
                  {headerComponent && (
                    <Col xs={24} sm={24}>
                      {headerComponent}
                    </Col>
                  )}
                </Row>
              )
            : undefined
        }
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size={size}
        loading={isLoading}
        bordered
        className='gx-table-responsive'
        dataSource={data}
        footer={footer}
        columns={mergedColumns}
        rowClassName='editable-row'
        pagination={pagination}
      />
    </Form>
  );
};

export default EditableTable;
