import { Popconfirm } from 'antd';
import IntlMessages from 'utils/IntlMessages';

function PopConfirm({
  children,
  disabled,
  onConfirm,
  onCancel,
  title,
}: {
  children: JSX.Element;
  disabled?: boolean;
  onConfirm?: any;
  onCancel?: any;
  title?: any;
}): JSX.Element {
  return (
    <Popconfirm
      disabled={disabled}
      okText={<IntlMessages id='app.yes' />}
      cancelText={<IntlMessages id='app.cancel' />}
      title={title ? title : <IntlMessages id='app.are-you-sure' />}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      {children}
    </Popconfirm>
  );
}

export default PopConfirm;
