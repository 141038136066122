import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';

const getDatasetLabel = (
  dataGroupId: string,
  datasetOption:
    | string
    | {
        key: string;
        model_name: string;
      },
  targetColumn: string | undefined
): string => {
  let name;
  if (isObject(datasetOption)) {
    if (datasetOption.key.includes(targetColumn + '_preds'))
      name =
        datasetOption.key.replace(targetColumn + '_preds', '') +
        datasetOption.model_name +
        'Prediction';
    if (datasetOption.key.includes(targetColumn + '_errors'))
      name =
        datasetOption.key.replace(targetColumn + '_errors', '') +
        datasetOption.model_name +
        'Hatalar';
  } else name = datasetOption;
  return dataGroupId + ' - ' + startCase(name);
};

export default getDatasetLabel;
