import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useGetStatusByRunIdMutation = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const getStatusByRunIdMutation = useMutation(
    async (run_id: string) => {
      return axios({
        method: 'get',
        url:
          process.env.REACT_APP_API +
          `/pipeline_status_view/status?run_id=${run_id}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return getStatusByRunIdMutation;
};

export default useGetStatusByRunIdMutation;
