import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetStationMapping = (): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['station_mapping'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/rmb/station_mapping`,
      });
      return data;
    },
    refetchOnWindowFocus: false,
  });
  return data_sources;
};

export default useGetStationMapping;
