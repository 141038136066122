import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { KeycloakResponse } from 'types/commonTypes';
import { axios } from 'utils';

const AUTH_URL = (process.env.REACT_APP_AUTH_API + '/auth') as string;

export type Credentials = { username: string; password: string };

export const useSignin = (): UseMutationResult<
  AxiosResponse,
  unknown,
  Credentials,
  unknown
> => {
  const navigate = useNavigate();
  const signin = useMutation(
    async (userInfo: Credentials) => {
      return axios(
        {
          method: 'post',
          data: userInfo,
          url: AUTH_URL as string,
        },
        false
      );
    },
    {
      onSuccess: (response) => {
        localStorage.setItem(
          'userName',
          new URLSearchParams(response.config.data).get('username') as string
        );
        localStorage.setItem(
          'token',
          JSON.stringify(response.data as KeycloakResponse)
        );
        navigate('/');
      },
      onError: () => {
        navigate('/signin');
      },
    }
  );
  return signin;
};

export default useSignin;
