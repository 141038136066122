export const getChangedObjectWithTitle = (
  changedData: any,
  rawData: any,
  parameter: string[],
  changedObjectWithParameter: any
) => {
  changedData.forEach((e: any) =>
    rawData.forEach((e2: any) => {
      if (e2.title === e.title)
        parameter.forEach((param) => {
          if (e2[param] !== e[param]) {
            if (Object.keys(changedObjectWithParameter).includes(e2.title))
              changedObjectWithParameter[e2.title] = {
                ...changedObjectWithParameter[e2.title],
                [param]: e[param],
              };
            else {
              changedObjectWithParameter[e2.title] = {
                [param]: e[param],
              };
            }
          }
        });
    })
  );
};
