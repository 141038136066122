import { Button, Card, Input, Tooltip, Form, Row, Spin, message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { SaveSelectedChartButton } from 'components';
import { useCreateFilterQuery, useUpdateFilterQuery } from 'hooks';
import { isEmpty } from 'lodash';
import { lazy, memo, Suspense, useState } from 'react';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { lazyRetry } from 'utils/lazyRetry';

import Chart from './Chart';
import ChartFilters from './ChartFilters';

const InfoTable = lazy(() => lazyRetry(() => import('./InfoTable')));

const { useForm } = Form;

const Home = (): JSX.Element => {
  const { mutateAsync: updateFilterQuery } = useUpdateFilterQuery();
  const { mutateAsync: createFilterQuery } = useCreateFilterQuery();

  const isFetchingChartData = useIsFetching({
    queryKey: ['filteredQuery'],
  });

  const [form] = useForm();

  const queryClient = useQueryClient();

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [lastAppliedFormFilters, setLastAppliedFormFilters] = useState<any>();

  const selectedChart = useSelector(
    (state: RootState) => state.chart.selectedChart
  );
  const queryParameter = useSelector(
    (state: RootState) => state.chart.queryParameters
  );

  const userInfo = useSelector((state: RootState) => state.main.userInfo);

  //const { data } = useFetchChartData();

  const showInput = () => {
    setInputVisible(true);
  };
  const handleSaveNewChart = async () => {
    if (inputValue) {
      const objectWillBeCreated = {} as any;
      objectWillBeCreated['user_id'] =
        userInfo?.first_name + ' ' + userInfo?.last_name;
      objectWillBeCreated['email'] = userInfo?.email;
      objectWillBeCreated['query_id'] = inputValue;
      objectWillBeCreated['json_data'] = lastAppliedFormFilters;
      objectWillBeCreated['run_id'] = selectedChart?.run_id
        ? selectedChart.run_id
        : queryParameter.run_id;
      objectWillBeCreated['is_past'] = selectedChart?.run_id
        ? selectedChart.is_past
        : queryParameter.is_past;
      objectWillBeCreated['layer'] = selectedChart?.run_id
        ? selectedChart.layer
        : queryParameter.layer;
      await createFilterQuery(objectWillBeCreated)
        .then((result: AxiosResponse) => {
          if (result.status === 200) {
            message.success('Grafik başarıyla oluşturuldu.');
          }
          queryClient.refetchQueries({
            queryKey: ['useGetFilterQueriesByUserId'],
            type: 'active',
          });
          setInputVisible(false);
          setInputValue('');
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 409) {
            message.error('Aynı isimde grafik oluşturamazsın ! ');
          }
        });
    }
  };

  const SaveChartButton = SaveSelectedChartButton(userInfo);
  const saveSelectedChart = async () => {
    await updateFilterQuery({
      id: selectedChart?.id,
      json_data: lastAppliedFormFilters,
    }).then((result: AxiosResponse) => {
      if (result.status === 200) {
        message.success('Grafik başarıyla kaydedildi .');
      } else {
        message.error('Grafik kaydedilirken hata oluştu !');
      }
    });
    queryClient.refetchQueries({
      queryKey: ['useGetFilterQueriesByUserId'],
      type: 'active',
    });
  };

  return (
    <Card style={{ width: '95%', margin: 50 }}>
      <div style={{ width: '100%' }}>
        {!isEmpty(selectedChart) &&
          userInfo?.first_name + ' ' + userInfo?.last_name ===
            selectedChart?.user_id && (
            <SaveChartButton
              onClick={saveSelectedChart}
              shared={selectedChart.is_shared}
              scheduled={!!selectedChart.scheduled_id}
            />
          )}

        {inputVisible ? (
          <Input
            placeholder='Grafik Adını Giriniz'
            style={{
              marginBottom: '5px',
              verticalAlign: 'middle',
              width: '187px',
            }}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={handleSaveNewChart}
            onBlur={handleSaveNewChart}
          />
        ) : (
          <Tooltip
            color={'#f50'}
            title="Grafik adını giriniz ve enter'a basınız "
          >
            <Button onClick={showInput}>Yeni Grafik Olarak Kaydet</Button>
          </Tooltip>
        )}
      </div>
      <ChartFilters
        setLastAppliedFormFilters={setLastAppliedFormFilters}
        queryParameter={queryParameter}
        selectedChart={selectedChart}
        form={form}
      />
      <br />
      <Chart
        isPast={!!selectedChart?.is_past || !!queryParameter?.is_past}
        isFetchingChartData={isFetchingChartData}
      />
      <Suspense
        fallback={
          <Row style={{ justifyContent: 'center', marginTop: 10 }}>
            <Spin tip='Yükleniyor...' size='default' />
          </Row>
        }
      >
        <InfoTable loading={isFetchingChartData} />
      </Suspense>
    </Card>
  );
};

export default memo(Home);
