import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useDeleteTimelyPrediction = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const deleteTimelyPrediction = useMutation(
    async (time: number) => {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_API + `/timely_prediction?time=${time}`,
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
  return deleteTimelyPrediction;
};

export default useDeleteTimelyPrediction;
