import { Button, Modal, message, Form } from 'antd';
import React, { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import IntlMessages from 'utils/IntlMessages';
import * as lngData from 'lngProvider/locales/tr_TR.json';
const langData = lngData as any;
const ForgotPassword: React.FC = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    message.info({
      content: langData['forgot-password.reset-cancel-success'],
      duration: 2,
    });
    setOpen(false);
    setConfirmLoading(false);
    form.resetFields();
  };

  return (
    <>
      <Button type='link' onClick={showModal}>
        {<IntlMessages id='app.forgot-password' />}
      </Button>
      <Modal
        title={<IntlMessages id='forgot-password.reset-password' />}
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <ForgotPasswordForm
          handleCancel={handleCancel}
          form={form}
          setOpen={setOpen}
        />
      </Modal>
    </>
  );
};

export default ForgotPassword;
