import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useUpdateMultipleCompositeRatios = (
  year: any
): UseMutationResult<AxiosResponse, unknown, any, unknown> => {
  const updateMultipleCompositeRatios = useMutation(
    async (data) => {
      return axios({
        method: 'post',
        data,
        url: process.env.REACT_APP_API + `/composite/update?year=${year}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return updateMultipleCompositeRatios;
};

export default useUpdateMultipleCompositeRatios;
