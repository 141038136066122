import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { checkToken } from 'utils';

const authorizedInstance = axios.create();
authorizedInstance.interceptors.request.use(
  (config) => {
    const token = checkToken();
    if (token) {
      const user_info = jwt_decode(token.access_token);
      if (user_info) {
        config.headers['Authorization'] = 'Bearer ' + token.access_token;
      }
    } else {
      return Promise.reject('You are not authorized!');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const instance = (
  { ...config }: AxiosRequestConfig,
  authorized = true
): Promise<AxiosResponse> =>
  authorized
    ? authorizedInstance({
        ...config,
      })
    : axios({
        ...config,
      });

export default instance;
