import { useDropzone } from 'react-dropzone';
import { Card, message, notification, Spin } from 'antd';
import { memo, useEffect } from 'react';
import IntlMessages from 'utils/IntlMessages';
import useSendWhatIfData from 'hooks/endpoints/WhatIf/useSendWhatIfData';
import { isEmpty } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import PopConfirm from './PopConfirm';
import { WhatIfFileNameCheck } from 'utils/WhatIfFileNameCheck';

function Dropzone({
  setDroppedFiles,
  droppedFiles,
  layer,
}: {
  setDroppedFiles: any;
  droppedFiles: any;
  layer: 'city' | 'rma' | undefined;
}): JSX.Element {
  const { mutateAsync: sendWhatIfData, isLoading } = useSendWhatIfData();
  const [api, contextHolder] = notification.useNotification();

  const onDrop = async (acceptedFiles: File[]) => {
    let tempAcceptedfiles = [...acceptedFiles];
    if (
      !isEmpty(
        acceptedFiles.filter((file: any) => {
          return !WhatIfFileNameCheck(file.path);
        })
      )
    ) {
      api.error({
        message: 'Dosya isimlerinin kaynak adını içermesi gerekir.',
        placement: 'bottomLeft',
        style: {
          width: '290px',
          height: '80px',
          padding: 10,
          fontWeight: '500',
        },
        duration: 10,
      });
    }
    if (
      !isEmpty(
        acceptedFiles.filter((file: any) =>
          droppedFiles.map((e: any) => e.path).includes(file.path)
        )
      )
    ) {
      api.info({
        message: 'Dosya yeniden yüklendi',
        placement: 'bottomLeft',
        style: {
          width: '290px',
          height: '80px',
          padding: 10,
          fontWeight: '500',
        },
        duration: 10,
      });
      await Promise.allSettled(
        acceptedFiles.map(async (file: any) => {
          return sendWhatIfData({ file: file, layer });
        })
      ).then((results: any) => {
        results.forEach((result: any) => {
          if (result.status === 'rejected') {
            message.error(
              `${result.reason.response.data.detail} Dosyanın indirilen dosya yapısı ile uyuştuğundan emin olunuz .`
            );
            tempAcceptedfiles = tempAcceptedfiles.filter((file: any) => {
              return file.path !== result.reason.response.data.detail;
            });
          }
        });
      });
    }

    await Promise.allSettled(
      acceptedFiles
        .filter(
          (file: any) =>
            WhatIfFileNameCheck(file.path) &&
            !droppedFiles.map((e: any) => e.path).includes(file.path)
        )
        .map(async (file: any) => {
          return sendWhatIfData({ file: file, layer });
        })
    ).then((results: any) => {
      results.forEach((result: any) => {
        if (result.status === 'rejected') {
          message.error(
            `${result.reason.response.data.detail} Dosyanın indirilen dosya yapısı ile uyuştuğundan emin olunuz .`
          );
          tempAcceptedfiles = tempAcceptedfiles.filter((file: any) => {
            return file.path !== result.reason.response.data.detail;
          });
        }
      });
    });
    setDroppedFiles((prevState: any) => [
      ...prevState,
      ...tempAcceptedfiles.filter((file: any) => {
        return (
          WhatIfFileNameCheck(file.path) &&
          !droppedFiles.map((e: any) => e.path).includes(file.path)
        );
      }),
    ]);
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    disabled: isLoading,
    onDrop,
  });
  // useEffect(() => {
  //   const sendFile = async () => {
  //     if (droppedFiles && tableName) {
  //       sendWhatIfData({ file: droppedFiles, tableName });
  //     }
  //   };
  //   sendFile();
  // }, [droppedFiles]);

  const files = !isEmpty(droppedFiles) ? (
    <>
      {droppedFiles.map((fileName: any) => (
        <div>
          <li key={fileName?.path}>
            {fileName?.path} - {fileName?.size} bytes
            <PopConfirm
              onConfirm={() =>
                setDroppedFiles((prevState: any) =>
                  prevState.filter((item: string) => item !== fileName)
                )
              }
            >
              <CloseOutlined style={{ color: 'red', marginLeft: 10 }} />
            </PopConfirm>
          </li>
        </div>
      ))}
    </>
  ) : (
    ''
  );
  return (
    <>
      {contextHolder}
      <Card
        loading={isLoading}
        style={{
          marginLeft: 152,
          height: '150px',
          width: '50%',
          cursor: isLoading ? undefined : 'pointer',
          color: '#535353',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragReject ? (
          <span>
            <IntlMessages id='sidebar.whatif-uploadcsv' />
          </span>
        ) : isDragActive ? (
          <span>
            <IntlMessages id='sidebar.whatif-dropfile' />
          </span>
        ) : (
          <span>
            <IntlMessages id='sidebar.whatif-inside' />
            <br></br>
            <IntlMessages id='sidebar.whatif-inside-next' />
            <b>
              <IntlMessages id='sidebar.whatif-inside-csv' />
            </b>
            <IntlMessages id='sidebar.whatif-inside-final' />
            <br></br>
          </span>
        )}
      </Card>
      <aside
        style={{
          marginLeft: 15,
          height: '150px',
          width: '90%',
          color: '#535353',
        }}
      >
        <h3 style={{ fontWeight: 'bold' }}>Yüklenen Dosyalar</h3>
        {isLoading ? <Spin tip='Yükleniyor...'></Spin> : <ul>{files}</ul>}
      </aside>
    </>
  );
}

export default memo(Dropzone);
