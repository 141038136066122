import { InfoCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Form,
  Tooltip,
  Switch,
  FormInstance,
  TreeSelect,
} from "antd";
import { useGetBreakdowns, useGetFilteredPrediction } from "hooks";
import { isEmpty } from "lodash";
import React, { memo, useEffect, useMemo, useState } from "react";
import { IChart, IFilteredQueryParameter, IQueryParameter } from "types/Chart";
import IntlMessages from "utils/IntlMessages";
import Information from "./Information";

const sectorOptions = [
  {
    name: "Abone",
    id: "Konut",
  },
  {
    name: "Sanayi",
    id: "Sanayi",
  },
  {
    name: "Elektrik",
    id: "Elektrik",
  },
];

const initialFormObject = {
  is_sum_breakdowns: false,
  breakdowns: [],
  is_sum_sectors: false,
  sectors: [],
  company: [],
  is_customer_portfolio: false,
};

const ChartFilters = ({
  form,
  setLastAppliedFormFilters,
  selectedChart,
  queryParameter,
}: {
  form: FormInstance;
  setLastAppliedFormFilters: any;
  selectedChart?: IChart;
  queryParameter: IQueryParameter;
}) => {
  const [filteredQueryParameters, setFilteredQueryParameters] =
    useState<IFilteredQueryParameter>();

  const { mutateAsync: getBreakdowns, data: breakdowns } = useGetBreakdowns();
  const { refetch: refetchFilteredQuery } = useGetFilteredPrediction(
    filteredQueryParameters
  );

  useEffect(() => {
    if (
      !!queryParameter?.run_id ||
      !!(
        queryParameter?.layer &&
        queryParameter?.start_date &&
        queryParameter?.end_date
      )
    )
      getBreakdowns(queryParameter).then((response: any) => {
        if (!isEmpty(response)) {
          if (queryParameter.layer === "city") {
            form.setFieldValue("breakdowns", ["ISTANBUL"]);
          } else if (queryParameter.layer === "rma") {
            form.setFieldValue("breakdowns", [
              response[Object.keys(response)[0]]?.at(0),
            ]);
          } else if (queryParameter.layer === "country") {
            form.setFieldValue("breakdowns", ["TURKIYE"]);
          }
        } else {
          form.setFieldValue("breakdowns", []);
        }
        form.submit();
      });
  }, [queryParameter]);

  useEffect(() => {
    if (filteredQueryParameters?.run_id) {
      refetchFilteredQuery();
    }
  }, [filteredQueryParameters]);

  useEffect(() => {
    const asyncApplyFilter = async () => {
      if (selectedChart?.json_data) {
        const filters = JSON.parse(selectedChart?.json_data);
        let dates;
        if (selectedChart.is_past) {
          dates = JSON.parse(selectedChart?.run_id);
        }
        form.setFieldsValue(filters);
        await getBreakdowns({
          run_id: selectedChart.run_id,
          is_past: selectedChart.is_past,
          end_date: dates?.end_date,
          start_date: dates?.start_date,
          layer: selectedChart.layer,
        }).then((response: any) => {
          if (isEmpty(filters.breakdowns)) {
            if (!isEmpty(response)) {
              if (selectedChart.layer === "city") {
                if (response.includes("ISTANBUL"))
                  form.setFieldValue("breakdowns", ["ISTANBUL"]);
                else form.setFieldValue("breakdowns", [response[0]]);
              } else if (selectedChart.layer === "rma") {
                form.setFieldValue("breakdowns", [
                  response[Object.keys(response)[0]]?.at(0),
                ]);
              } else if (selectedChart.layer === "country") {
                form.setFieldValue("breakdowns", ["TURKIYE"]);
              }
            } else {
              form.setFieldValue("breakdowns", []);
            }
          }
        });
      } else {
        form.setFieldsValue(initialFormObject);
      }
      form.submit();
    };
    asyncApplyFilter();
  }, [selectedChart]);

  const rmaCascaderOptions = useMemo(() => {
    if (breakdowns && !Array.isArray(breakdowns))
      return Object.keys(breakdowns).map((key: string) => {
        return {
          value: key,
          label: key,
          selectable: false,
          children: breakdowns[key].map((child: any) => {
            return {
              value: child,
              label: child,
            };
          }),
        };
      });
    else return [];
  }, [breakdowns]);

  const filterApplied = (values: any) => {
    console.log(values);
    const object = { ...values } as IFilteredQueryParameter;
    setLastAppliedFormFilters(JSON.stringify(values));
    object["run_id"] = selectedChart
      ? selectedChart?.run_id
      : queryParameter.run_id;
    object["is_past"] = selectedChart
      ? selectedChart?.is_past
      : queryParameter.is_past;
    object["layer"] = selectedChart
      ? selectedChart?.layer
      : queryParameter.layer;
    setFilteredQueryParameters(object);
  };

  return (
    <>
      <Row style={{ justifyContent: "space-between" }}>
        <Col>
          <Form
            onFinish={filterApplied}
            form={form}
            initialValues={initialFormObject}
            layout="inline"
            wrapperCol={{ span: 24 }}
          >
            <Form.Item>
              <Form.Item
                name="is_sum_breakdowns"
                label="Kırılım Toplamı Göster"
                valuePropName="checked" // to set switch value by form object , we need this props
              >
                <Switch size="small" />
              </Form.Item>
              <Form.Item name="breakdowns">
                {breakdowns && Array.isArray(breakdowns) ? (
                  <Select
                    allowClear
                    mode="multiple"
                    style={{ minWidth: "300px" }}
                    showArrow
                    placeholder={<IntlMessages id="app.select-layer" />}
                    dropdownRender={(data) => {
                      return (
                        <React.Fragment>
                          <Button
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              form.setFieldValue(
                                "breakdowns",
                                breakdowns?.map((e: any) => e)
                              );
                            }}
                          >
                            <IntlMessages id="app.selectall" />
                          </Button>
                          <Button
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              form.setFieldValue("breakdowns", []);
                            }}
                          >
                            <IntlMessages id="app.deselectall" />
                          </Button>
                          {data}
                        </React.Fragment>
                      );
                    }}
                  >
                    {breakdowns?.map((e: any) => (
                      <Input type="checkbox" value={e}>
                        {e}
                      </Input>
                    ))}
                  </Select>
                ) : (
                  <TreeSelect
                    allowClear
                    showCheckedStrategy="SHOW_CHILD"
                    fieldNames={{ label: "label", children: "children" }}
                    multiple
                    style={{ minWidth: "300px" }}
                    treeData={rmaCascaderOptions}
                    showArrow
                    treeCheckable
                    placeholder={<IntlMessages id="app.select-layer" />}
                    dropdownRender={(data) => {
                      return (
                        <React.Fragment>
                          {breakdowns && (
                            <>
                              <Button
                                style={{ marginLeft: 5 }}
                                onClick={() => {
                                  form.setFieldValue(
                                    "breakdowns",
                                    Object.keys(breakdowns)
                                      .map((key) => {
                                        return breakdowns[key];
                                      })
                                      .flat()
                                  );
                                }}
                              >
                                <IntlMessages id="app.selectall" />
                              </Button>
                              <Button
                                style={{ marginLeft: 5 }}
                                onClick={() => {
                                  form.setFieldValue("breakdowns", []);
                                }}
                              >
                                <IntlMessages id="app.deselectall" />
                              </Button>
                            </>
                          )}
                          {data}
                        </React.Fragment>
                      );
                    }}
                  />
                )}
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                valuePropName="checked"
                name="is_sum_sectors"
                label="Sektör Toplamı Göster"
              >
                <Switch size="small" />
              </Form.Item>
              <Form.Item name="sectors">
                <Select
                  allowClear
                  mode="multiple"
                  style={{ minWidth: "300px" }}
                  showArrow
                  placeholder={<IntlMessages id="app.select-sector" />}
                  dropdownRender={(data) => (
                    <React.Fragment>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          form.setFieldValue(
                            "sectors",
                            sectorOptions.map((e) => e.id)
                          );
                        }}
                      >
                        <IntlMessages id="app.selectall" />
                      </Button>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          form.setFieldValue("sectors", []);
                        }}
                      >
                        <IntlMessages id="app.deselectall" />
                      </Button>
                      {data}
                    </React.Fragment>
                  )}
                >
                  {sectorOptions.map((e) => (
                    <Input type="checkbox" value={e.id}>
                      {e.name}
                    </Input>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                valuePropName="checked"
                name="is_customer_portfolio"
                label="Müşteri portföyünü  uygula"
              >
                <Switch
                  size="small"
                  onChange={(value: boolean) => {
                    if (!value) form.setFieldValue("company", []);
                  }}
                />
              </Form.Item>
              <Form.Item name="company">
                <Select
                  disabled={!Form.useWatch("is_customer_portfolio", form)}
                  allowClear
                  mode="multiple"
                  style={{ minWidth: "300px" }}
                  showArrow
                  placeholder={"Müşteri Portföyü Seçiniz"}
                  dropdownRender={(data) => (
                    <React.Fragment>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          form.setFieldValue("company", ["botas", "diger"]);
                        }}
                      >
                        <IntlMessages id="app.selectall" />
                      </Button>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          form.setFieldValue("company", []);
                        }}
                      >
                        <IntlMessages id="app.deselectall" />
                      </Button>
                      {data}
                    </React.Fragment>
                  )}
                >
                  <Input type="checkbox" value={"diger"}>
                    {"Diğer"}
                  </Input>
                  <Input type="checkbox" value={"botas"}>
                    {"BOTAS"}
                  </Input>
                </Select>
              </Form.Item>
            </Form.Item>
          </Form>
        </Col>
        <Col style={{ marginRight: 15 }}>
          <br />
          <br />
          <span>
            <Tooltip
              color={"#f50"}
              title={<IntlMessages id="app.apply-tooltip" />}
            >
              <InfoCircleTwoTone
                style={{ fontSize: "20px", color: "#08c", marginRight: 10 }}
              />
            </Tooltip>
          </span>
          <Button
            disabled={isEmpty(Form.useWatch("breakdowns", form))}
            onClick={() => form.submit()}
          >
            <IntlMessages id="app.apply" />
          </Button>
        </Col>
      </Row>
      <Information
        breakdowns={form.getFieldsValue(["breakdowns"]).breakdowns}
      />
    </>
  );
};

export default memo(ChartFilters);
