import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RMALoop } from 'types/RMA';

import { axios } from 'utils';

const useGetAlgorithmRmaLoop = (): UseQueryResult<RMALoop[]> => {
  const data_sources = useQuery({
    queryKey: ['rma_loop_algo_true'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/rma_loop/algo_true`,
      });
      return data;
    },
  });
  return data_sources;
};

export default useGetAlgorithmRmaLoop;
