import { useState, useEffect, memo, Suspense } from 'react'
import { Chart as ChartType, ChartData } from 'chart.js'
import { isEmpty } from 'lodash'
import getDatasetLabel from './getDatasetLabel'
import { useDispatch } from 'react-redux'
import { chartActions } from 'redux/slices/chartSlice'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Card, Row, Spin } from 'antd'
import { lazyRetry } from 'utils/lazyRetry'

const LineChart = React.lazy(() => lazyRetry(() => import('../LineChart')))

const CHART_DATA_SIZE_LIMIT = parseInt(process.env.REACT_APP_CHART_DATA_SIZE_LIMIT || '5000')

const getActualLabelColor = (numOfBars: number) => {
  let colors = []
  for (let i = 0; i < numOfBars; i++) {
    let color = 'rgb('
    color += Math.floor(Math.random() * 50) + ','
    color += Math.floor(Math.random() * 185) + 70 + ','
    color += Math.floor(Math.random() * 50) + ')'
    colors.push(color)
  }
  return colors
}

const Chart = ({ isFetchingChartData, isPast }: any): JSX.Element => {
  const dispatch = useDispatch()

  const [chartInstance, setChartInstance] = useState<ChartType>()

  const queryClient = useQueryClient()

  //const chartData = mockData;

  const chartData = queryClient.getQueryData(['filteredQuery']) as any
  /**when reload , gets config from localstorage and refecthing graph datas */

  const [dataSizeError, setDataSizeError] = useState(false)
  useEffect(() => {
    if (chartData) {
      setDataSizeError(false)
      chartData.forEach((cd: any) => {
        if (cd.results.length > CHART_DATA_SIZE_LIMIT) {
          setDataSizeError(true)
        }
      })
    }
  }, [chartData])

  const [chartjsData, setChartjsData] = useState<ChartData<'line'>>({
    datasets: [],
  })
  const [annotations, setAnnotations] = useState<Record<string, unknown>>({})

  useEffect(() => {
    const isDataGroupPresent = chartData?.find((dataGroup: any) => !isEmpty(dataGroup.results))
    if (chartData && isDataGroupPresent) {
      const newChartjsData: ChartData<'line'> = { datasets: [] }
      const infoTableData: any = []

      let xMin = Infinity
      let xMax = 0
      for (const [dataGroupIndex, dataGroup] of chartData.entries()) {
        const data = dataGroup.results as any
        for (const dataUnit of data) {
          for (const key of Object.keys(dataUnit)) {
            const actualColor = dataGroup.id.includes('ABONE') ? 'green' : dataGroup.id.includes('SANAYI') ? '#e17b0e' : 'blue'

            const x = new Date(dataUnit.date).getTime()
            let y
            // TODO : sıcaklık değeri gelince düzenlenecek
            if (key === 'temperature') {
            } else if (key !== 'date' && !key.includes('anomaly') && key !== 'predictions') {
              y = dataUnit[key]
              if (y !== '') {
                const label = getDatasetLabel(dataGroup.id, key, 'value')
                let dataset = newChartjsData.datasets.find((dataset) => dataset.label === label)
                let infoTableDataObject = infoTableData.find((infoObject: any) => infoObject.label === label) ?? {}
                if (!isEmpty(infoTableDataObject)) {
                  infoTableDataObject[x] = y
                } else {
                  infoTableDataObject['label'] = label
                  infoTableDataObject[x] = y
                  infoTableData.push(infoTableDataObject)
                }
                if (dataset) {
                  dataset.data.push({ x, y })
                } else {
                  dataset = {
                    label,
                    data: [{ x, y }],
                    hidden: false,
                    fill: false,
                    backgroundColor: actualColor,
                    borderColor: actualColor,
                    pointRadius: 2,
                    pointStyle: 'circle',
                    pointHoverRadius: 10,
                    borderWidth: 3,
                  }
                  newChartjsData.datasets.push(dataset)
                }
              }
            } else if (key === 'predictions') {
              for (const [, option] of dataUnit.predictions.entries()) {
                for (const predictionKey of Object.keys(option)) {
                  const modelColor = dataGroup.id.includes('ABONE') ? 'green' : dataGroup.id.includes('SANAYI') ? '#e17b0e' : 'blue'

                  if (predictionKey !== 'model_name') {
                    y = option[predictionKey]
                    if (y !== '') {
                      const label = getDatasetLabel(
                        dataGroup.id,
                        {
                          key: predictionKey,
                          model_name: option['model_name'],
                        },
                        'value'
                      )
                      let infoTableDataObject = infoTableData.find((infoObject: any) => infoObject.label === label) ?? {}

                      if (!isEmpty(infoTableDataObject)) {
                        infoTableDataObject[x] = y
                      } else {
                        infoTableDataObject['label'] = label
                        infoTableDataObject[x] = y
                        if (isPast || !label.includes('Hatalar')) infoTableData.push(infoTableDataObject)
                      }

                      let dataset = newChartjsData.datasets.find((dataset) => dataset.label === label)
                      if (dataset) {
                        dataset.data.push({ x, y })
                      } else {
                        dataset = {
                          label,
                          data: [{ x, y }],
                          hidden: false,
                          fill: false,
                          backgroundColor: modelColor,
                          borderColor: modelColor,
                          pointRadius: label.includes('DİĞER') ? 4 : 2,
                          pointStyle: label.includes('DİĞER') ? 'triangle' : 'circle',
                          borderWidth: label.includes('Hatalar') ? 2 : 1,
                          borderDash: label.includes('Hatalar') ? [5, 5] : [0, 0],
                          // borderDash: [5, 5], for dashed line
                          // yAxisID: "y1", // TODO : y ekseninde sağa bir şey eklemek istersek sıcaklık gibi burası kullanılacak
                        }
                        newChartjsData.datasets.push(dataset)
                      }
                    }
                  }
                }
              }
            } else if (key.includes('anomaly')) {
              y = dataUnit[key]
              if (y !== '') {
                const label = dataGroup.id + ' Anomali'
                let dataset = newChartjsData.datasets.find((dataset) => dataset.label === label)

                if (dataset) {
                  dataset.data.push()
                } else {
                  dataset = {
                    label,
                    data: [],
                    hidden: false,
                    fill: false,
                    backgroundColor: 'black',
                    borderColor: 'black',
                    pointRadius: 2,
                    pointStyle: 'circle',
                    pointHoverRadius: 10,
                    borderWidth: 3,
                  }
                  newChartjsData.datasets.push(dataset)
                }
              }
            }
            if (xMax < x) xMax = x
            if (xMin > x) xMin = x
          }
        }
      }
      setChartjsData(newChartjsData)
      let annotations: { [key: string]: any } = {
        // ...getTrainAndTestAnnotations(xMin, xMax, { // TODO : Train ve Prediction background color , Run Metadata buraya gelicek ve metadata kontrolu eklenicek.
        //   description: '55107310',
        //   train_start_date: '2018-01-01 00:00:00',
        //   train_end_date: '2019-12-31 00:00:00',
        // }),
      }
      dispatch(
        chartActions.setChartDates({
          startDate: xMin,
          endDate: xMax,
        })
      )
      dispatch(chartActions.setInfoTableData(infoTableData))
      setAnnotations({ ...annotations })
    } else {
      dispatch(chartActions.setInfoTableData([]))
      setChartjsData({ datasets: [] })
    }
  }, [chartData])

  return (
    <>
      {/* {isFetching ? <div>loading</div> : null} */}
      {!dataSizeError && chartjsData.datasets.length > 0 ? (
        <Suspense
          fallback={
            <Row style={{ justifyContent: 'center', marginTop: 10 }}>
              <Spin tip='Yükleniyor...' size='default' />
            </Row>
          }
        >
          <Card loading={!!isFetchingChartData}>
            <LineChart
              isPast={isPast}
              chartInstance={chartInstance}
              setChartInstance={setChartInstance}
              chartData={
                chartData as {
                  id: string
                  results: any[]
                }[]
              }
              chartjsData={chartjsData}
              annotations={annotations}
              setAnnotations={setAnnotations}
            />
          </Card>
        </Suspense>
      ) : (
        <Card loading={!!isFetchingChartData}>Grafik verisi bulunamadı !</Card>
      )}
    </>
  )
}

export default memo(Chart)
