import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChart, IQueryParameter } from 'types/Chart';

export const chartSlice = createSlice({
  name: 'chartSlice',
  initialState: {
    chartDates: {
      start_date: null,
      end_date: null,
    } as any,
    selectedChart: {} as IChart | undefined,
    infoTableData: [] as any,
    queryParameters: {} as IQueryParameter,
  },
  reducers: {
    setSelectedChart(state, { payload }: PayloadAction<IChart | undefined>) {
      state.selectedChart = payload;
    },
    setChartDates(
      state,
      {
        payload,
      }: PayloadAction<{
        startDate: number;
        endDate: number;
      }>
    ) {
      state.chartDates.start_date = payload.startDate;
      state.chartDates.end_date = payload.endDate;
    },
    setInfoTableData(state, { payload }: PayloadAction<any>) {
      state.infoTableData = payload;
    },
    setQueryParameters(state, { payload }: PayloadAction<IQueryParameter>) {
      state.queryParameters = payload;
    },
  },
});

export const chartActions = chartSlice.actions;
export const chartReducer = chartSlice.reducer;
