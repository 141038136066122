import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useDeleteAlarmEmail = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const deleteAlarmEmail = useMutation(
    async (email: string) => {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_API + `/alarm_email?email=${email}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return deleteAlarmEmail;
};

export default useDeleteAlarmEmail;
