import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useDeleteRMALoop = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const deleteRMALoop = useMutation(
    async ({ rma_loop_id }: { rma_loop_id: any }) => {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_API + `/rma_loop/${rma_loop_id}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return deleteRMALoop;
};

export default useDeleteRMALoop;
