import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';
import { User } from 'types/commonTypes';

export const useDeleteUser = (): UseMutationResult<
  AxiosResponse,
  unknown,
  User,
  unknown
> => {
  const deleteUser = useMutation(
    async (user: User) => {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_AUTH_API + `/admin/user/${user.email}`,
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
  return deleteUser;
};

export default useDeleteUser;
