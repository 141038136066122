import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  FormInstance,
  message,
} from "antd";
import useSendResetPassword from "hooks/endpoints/User/useSendResetPassword";
import { useState } from "react";
import IntlMessages from "utils/IntlMessages";
import * as lngData from "lngProvider/locales/tr_TR.json";
const langData = lngData as any;
const ForgotPasswordForm = ({
  form,
  setOpen,
  handleCancel,
}: {
  form: FormInstance;
  setOpen: any;
  handleCancel: any;
}) => {
  const { mutateAsync: sendResetPassword } = useSendResetPassword();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    await sendResetPassword(values).catch(() => {
      message.error("Şifre sıfırlanırken hata oluştu!");
    });
    setLoading(false);
    setOpen(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="email"
        label={<IntlMessages id="forgot-password.mail" />}
        rules={[
          {
            required: true,
            message: <IntlMessages id="forgot-password.required" />,
            type: "email",
          },
        ]}
      >
        <Input
          allowClear
          placeholder={langData["forgot-password.placeholder"]}
        />
      </Form.Item>

      <Divider />

      <Row justify="end" style={{ flexDirection: "row" }}>
        <Col>
          <Form.Item>
            <Button
              onClick={() => {
                setOpen(false);
                handleCancel();
              }}
              danger
              style={{ color: "#ed4f24" }}
              htmlType="reset"
            >
              <IntlMessages id="forgot-password.cancel-button" />
            </Button>
          </Form.Item>
        </Col>

        <Col>
          <Form.Item>
            <Button
              style={{ margin: "0 10px 0 10px" }}
              loading={loading}
              key="submit"
              type="primary"
              htmlType="submit"
            >
              <IntlMessages id="forgot-password.send-button" />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default ForgotPasswordForm;
