import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IChart } from 'types/Chart';
import { User } from 'types/commonTypes';

import { axios } from 'utils';

const useGetFilterQueriesByUserId = (
  userInfo: User | null
): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['useGetFilterQueriesByUserId'],
    queryFn: async (): Promise<IChart[]> => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API + `/filter_query?email=${userInfo?.email}`,
      });

      return data;
    },
    enabled: !!userInfo,
  });
  return data_sources;
};
export default useGetFilterQueriesByUserId;
