import { objectPut } from './ObjectUtils';
import { RMB } from 'types/RMBTypes';
import { monthList } from 'utils';

export const TransposeRMBToRatio = (data: RMB[]) => {
  const mappingData = {} as any;

  data.forEach((e: any) => {
    if (Object.keys(mappingData).includes(e.rmb_name)) {
      mappingData[e.rmb_name].push(e);
    } else {
      mappingData[e.rmb_name] = [];
      mappingData[e.rmb_name].push(e);
    }
  });

  const result = Object.keys(mappingData)?.map((rmb_name: string) => {
    const compoziteRatios = {} as any;
    mappingData[rmb_name]?.forEach((t: any) =>
      objectPut(compoziteRatios, `${t.month}`, t)
    );
    const list = monthList?.map((month, index) => {
      return { [month]: compoziteRatios[index + 1]?.ratio };
    });
    const finalObject = list?.reduce((a, b) => {
      return { ...a, ...b };
    });
    finalObject['title'] = rmb_name;
    finalObject['rmb_point_code'] = mappingData[rmb_name][0].rmb_point_code;
    finalObject['sector_name'] = mappingData[rmb_name][0].sector_name;
    finalObject['sub_sector_name'] = mappingData[rmb_name][0].sub_sector_name;
    finalObject['distribution_company_name'] =
      mappingData[rmb_name][0].distribution_company_name;
    finalObject['city_name'] = mappingData[rmb_name][0].city_name;
    return finalObject;
  });
  return result;
};

export const TransposeRMBToCustomerRatio = (data: any) => {
  const result = data.map((element: any) => {
    const clientPortfolioRatios = {} as any;
    element.client_portfolio_ratios?.forEach((t: any) =>
      objectPut(clientPortfolioRatios, `${t.month}`, t)
    );
    const list = monthList.map((month, index) => {
      return {
        [month]: clientPortfolioRatios[index + 1]?.client_portfolio_ratio,
      };
    });
    const finalObject = list.reduce((a, b) => {
      return { ...a, ...b };
    });
    finalObject['title'] = element.rmb_name;
    finalObject['rmb_point_code'] = element.rmb_point_code;
    finalObject['sector_name'] = element.sector_name;
    finalObject['sub_sector_name'] = element.sub_sector_name;
    finalObject['distribution_company_name'] =
      element.distribution_company_name;
    finalObject['city_name'] = element.city_name;
    return finalObject;
  });
  return result;
};
