import { useState, useEffect } from 'react';

import { Navigate } from 'react-router-dom';

import { useRefreshToken } from 'hooks';
import { checkToken } from 'utils';

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const { isError, isFetched } = useRefreshToken();
  const [isRedirect, setIsRedirect] = useState(!checkToken());

  useEffect(() => {
    setIsRedirect(isError && isFetched);
  }, [isError, isFetched]);

  if (isRedirect) {
    return <Navigate to='/signin' />;
  }

  return children;
}

export default RequireAuth;
