import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useUpdateRma = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const updateRma = useMutation(async (rmaUpdateList: any) => {
    return axios({
      method: 'post',
      data: rmaUpdateList,
      url: process.env.REACT_APP_API + `/rma/update`,
    });
  });
  return updateRma;
};

export default useUpdateRma;
