import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetAllAlarmEmail = (): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['alarm-email'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/alarm_email`,
      });
      return data;
    },
  });
  return data_sources;
};

export default useGetAllAlarmEmail;
