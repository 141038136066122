import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetSystemSettings = (): UseQueryResult<any> => {
  const data_sources = useQuery({
    queryKey: ['system-settings'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/system_settings`,
      });
      return data;
    },
  });
  return data_sources;
};

export default useGetSystemSettings;
