import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from 'utils';

const useGetAllSubSector = (): UseQueryResult<any[]> => {
  const data_sources = useQuery({
    queryKey: ['sub_sector'],
    queryFn: async (): Promise<any[]> => {
      const { data } = await axios({
        url: process.env.REACT_APP_API + `/sub_sector`,
      });
      return data;
    },
  });
  return data_sources;
};

export default useGetAllSubSector;
