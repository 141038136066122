import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { IChart } from 'types/Chart';
import { axios } from 'utils';

export const useDeleteFilterQuery = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const deleteFilterQuery = useMutation(
    async (id: any) => {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_API + `/filter_query?id=${id}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return deleteFilterQuery;
};

export default useDeleteFilterQuery;
