import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IRunStatus } from 'types/commonTypes';

import { axios } from 'utils';

const useGetStatusByRunId = (
  run_id: string | undefined,
  refetchInterval: number
): UseQueryResult<IRunStatus> => {
  const data_sources = useQuery({
    queryKey: ['run_status'],
    queryFn: async (): Promise<IRunStatus> => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/pipeline_status_view/status?run_id=${run_id}`,
      });
      return data;
    },
    enabled: !!run_id,
    refetchInterval: refetchInterval,
    retry: false,
  });
  return data_sources;
};

export default useGetStatusByRunId;
