import { groupBy } from 'lodash';
import { IScores } from 'types/Chart';
export const TransposeScores = (scores: IScores[] | undefined) => {
  const groupedScores = groupBy(scores, 'kirilim') as any;
  Object.keys(groupedScores).forEach((e: any) => {
    groupedScores[e] = groupBy(groupedScores[e], 'model_name');
  });

  return groupedScores;
};
