import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useDeleteSubSector = (): UseMutationResult<
  AxiosResponse,
  unknown,
  any,
  unknown
> => {
  const deleteSubSector = useMutation(
    async ({ deletedName }: { deletedName: any }) => {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_API + `/sub_sector/${deletedName}`,
      });
    },
    {
      onSuccess: (response) => {},
      onError: () => {},
    }
  );
  return deleteSubSector;
};

export default useDeleteSubSector;
