export const getChangedObjectWithRmbPointCode = (
  changedData: any,
  rawData: any,
  parameter: string[],
  changedObjectWithParameter: any
) => {
  changedData.forEach((e: any) =>
    rawData.forEach((e2: any) => {
      if (e2.rmb_point_code === e.rmb_point_code)
        parameter.forEach((param) => {
          if (e2[param] !== e[param]) {
            if (
              Object.keys(changedObjectWithParameter).includes(
                e2.rmb_point_code
              )
            )
              changedObjectWithParameter[e2.rmb_point_code] = {
                ...changedObjectWithParameter[e2.rmb_point_code],
                [param]: e[param],
              };
            else {
              changedObjectWithParameter[e2.rmb_point_code] = {
                [param]: e[param],
              };
            }
          }
        });
    })
  );
};
