export const WhatIfFileNameCheck = (fileName: string) => {
  return (
    (fileName.includes('sue') ||
      fileName.includes('kgup') ||
      fileName.includes('hava_durumu')) &&
    ((fileName.includes('sue') &&
      !fileName.includes('kgup') &&
      !fileName.includes('hava_durumu')) ||
      (fileName.includes('kgup') &&
        !fileName.includes('sue') &&
        !fileName.includes('hava_durumu')) ||
      (fileName.includes('hava_durumu') &&
        !fileName.includes('sue') &&
        !fileName.includes('kgup')))
  );
};
