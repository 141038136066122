import { Button } from 'antd';
import { User } from 'types/commonTypes';

const SharedOutlinedRoleBased =
  (userInfo: User | null) =>
  ({
    onClick,
    scheduled,
    shared,
  }: {
    onClick: any;
    scheduled?: boolean;
    shared?: boolean;
  }) => {
    if ((userInfo?.groups.includes('botas-admin') || !shared) && !scheduled)
      return <Button onClick={onClick}> Seçili Grafiğe Kaydet</Button>;
    else return <></>;
  };

export default SharedOutlinedRoleBased;
