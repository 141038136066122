import { DeleteFilled } from '@ant-design/icons';
import PopConfirm from 'components/PopConfirm';
import { User } from 'types/commonTypes';

const DeleteOutlinedRoleBased =
  (userInfo: User | null) =>
  ({
    onConfirm,
    onClick,
    style,
    scheduled,
    shared,
  }: {
    onConfirm: any;
    onClick: any;
    style: any;
    scheduled?: boolean;
    shared?: boolean;
  }) => {
    if (userInfo?.groups.includes('botas-admin') && !shared && !scheduled)
      return (
        <PopConfirm
          onCancel={(e: any) => e.stopPropagation()}
          onConfirm={(e: any) => {
            onConfirm();
            e.stopPropagation();
          }}
        >
          <DeleteFilled style={style} onClick={onClick} />
        </PopConfirm>
      );
    else return <></>;
  };

export default DeleteOutlinedRoleBased;
