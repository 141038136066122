import { TimePicker } from 'antd';
import * as lngData from 'lngProvider/locales/tr_TR.json';
import moment from 'moment';

function HourPicker({
  setFieldValue,
  name,
  onClick,
  format = 'HH:mm',
  value,
  minuteStep,
}: {
  setFieldValue: any;
  name: string;
  onClick?: any;
  format?: any;
  value?: any;
  minuteStep?: number;
}): JSX.Element {
  const langData = lngData as any;
  return (
    <TimePicker
      minuteStep={minuteStep ?? 1}
      value={value}
      style={{ minWidth: '141px' }}
      placeholder={langData['system-settings.select-time']}
      onChange={(value: any) => {
        if (minuteStep === 60)
          setFieldValue(name, moment(value?.format('HH:00'), 'HH:00'));
        else if (minuteStep === 15) {
          if (value?.minute() > 0 && value?.minute() < 15) {
            setFieldValue(name, moment(value?.format('HH:15'), 'HH:mm'));
          } else if (value?.minute() > 15 && value?.minute() < 30) {
            setFieldValue(name, moment(value?.format('HH:30'), 'HH:mm'));
          } else if (value?.minute() > 30 && value?.minute() < 45) {
            setFieldValue(name, moment(value?.format('HH:45'), 'HH:mm'));
          } else if (value?.minute() > 45 && value?.minute() < 60) {
            setFieldValue(
              name,
              moment(value?.add(1, 'h')?.format('HH:00'), 'HH:mm')
            );
          } else {
            setFieldValue(name, moment(value?.format('HH:mm'), 'HH:mm'));
          }
        }
      }}
      onClick={
        onClick
          ? onClick
          : () => {
              const nowButtonCollection =
                document.getElementsByClassName('ant-picker-now-btn');
              for (let i = 0; i < nowButtonCollection.length; i++) {
                nowButtonCollection[i].innerHTML = langData['app.now'];
              }
              const okButtonCollection =
                document.getElementsByClassName('ant-picker-ok');
              for (let i = 0; i < okButtonCollection.length; i++) {
                okButtonCollection[i].children[0].innerHTML =
                  langData['app.ok'];
              }
            }
      }
      format={format}
    />
  );
}

export default HourPicker;
