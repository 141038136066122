import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils';

export const useUpdateRmb = (
  year: string,
  queryClient?: any
): UseMutationResult<AxiosResponse, unknown, any, unknown> => {
  const updateRmb = useMutation(
    async (rmb: any) => {
      return axios({
        method: 'post',
        data: rmb,
        url:
          process.env.REACT_APP_API + `/rmb/${rmb.rmb_point_code}?year=${year}`,
      });
    },
    {
      onSuccess: (response) => {
        queryClient?.refetchQueries({
          queryKey: ['rmb'],
          stale: true,
        });
        queryClient?.refetchQueries({
          queryKey: ['sector'],
          stale: true,
        });
        queryClient?.refetchQueries({
          queryKey: ['subsector'],
          stale: true,
        });
      },
      onError: () => {},
    }
  );
  return updateRmb;
};

export default useUpdateRmb;
