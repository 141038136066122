import { Layout, Spin } from 'antd'
import { lazy, memo, Suspense, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { mainActions, mainSlice } from 'redux/slices/mainSlice'
import { useDispatch } from 'react-redux'
import Settings from 'containers/Settings/Settings'

import Home from 'routes/Home'
import useGetUserInfo from 'hooks/endpoints/User/useGetUserInfo'
import AccessDenied from 'routes/AccessDenied'
import SettingsHOC from 'containers/Settings/SettingsHOC'
import { User } from 'types/commonTypes'
import { lazyRetry } from 'utils/lazyRetry'
import { Help } from 'routes/Help'
import { DataBossLogo } from 'assets/DataBossLogo'

const { Content } = Layout
const Topbar = lazy(() => lazyRetry(() => import('containers/Topbar')))
const AppSidebar = lazy(() => lazyRetry(() => import('./AppSideBar')))
const RMBSector = lazy(() => lazyRetry(() => import('routes/RMB-Sector')))
const Account = lazy(() => lazyRetry(() => import('routes/Account')))
const UserManagement = lazy(() =>
  lazyRetry(() => import('routes/UserManagement'))
)
const SystemLogs = lazy(() => lazyRetry(() => import('routes/SystemLogs')))
const SystemSettings = lazy(() =>
  lazyRetry(() => import('routes/SystemSettings'))
)

const RestrictedRoute = ({
  children,
  userInfo,
}: {
  children: JSX.Element
  userInfo: User | undefined
}) => {
  if (userInfo?.groups.includes('botas-admin')) return children
  else return <AccessDenied />
}

const MainApp = () => {
  const pathname = useLocation().pathname
  const dispatch = useDispatch()
  const { data: userInfo } = useGetUserInfo()

  useEffect(() => {
    if (userInfo) {
      dispatch(mainActions.setUserInfo(userInfo))
    }
  }, [userInfo])

  let [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(mainSlice.actions.setCommonData({ width: window.innerWidth }))
    })
  }, [])

  const RoleBasedSettings = SettingsHOC(Settings)

  return (
    <Layout className='gx-app-layout'>
      <Suspense fallback={<Spin tip='Yükleniyor...' size='default' />}>
        {pathname === '/' ? (
          <AppSidebar
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
        ) : null}
      </Suspense>

      <Layout>
        <Suspense fallback={<Spin tip='Yükleniyor...' size='default' />}>
          <Topbar
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
        </Suspense>

        <Content className={`gx-layout-content gx-container-wrap `}>
          <Routes>
            <Route
              path='/settings.system-info'
              element={
                <Suspense
                  fallback={<Spin tip='Yükleniyor...' size='default' />}
                >
                  <RestrictedRoute userInfo={userInfo}>
                    <SystemLogs />
                  </RestrictedRoute>
                </Suspense>
              }
            />
            <Route
              path='/settings.system-settings'
              element={
                <Suspense
                  fallback={<Spin tip='Yükleniyor...' size='default' />}
                >
                  <RestrictedRoute userInfo={userInfo}>
                    <SystemSettings />
                  </RestrictedRoute>
                </Suspense>
              }
            />
            <Route
              path='/settings.RMB-sector'
              element={
                <Suspense
                  fallback={<Spin tip='Yükleniyor...' size='default' />}
                >
                  <RestrictedRoute userInfo={userInfo}>
                    <RMBSector />
                  </RestrictedRoute>
                </Suspense>
              }
            />
            <Route
              path='/settings.user-management'
              element={
                <Suspense
                  fallback={<Spin tip='Yükleniyor...' size='default' />}
                >
                  <RestrictedRoute userInfo={userInfo}>
                    <UserManagement />
                  </RestrictedRoute>{' '}
                </Suspense>
              }
            />
            <Route path='/help' element={<Help />} />
          </Routes>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route
              path='/Account'
              element={
                <Suspense
                  fallback={<Spin tip='Yükleniyor...' size='default' />}
                >
                  <Account />
                </Suspense>
              }
            />
          </Routes>
        </Content>
      </Layout>
      <RoleBasedSettings userInfo={userInfo} />
      <DataBossLogo />
    </Layout>
  )
}

export default memo(MainApp)
